
<template>
  <div>
    <b-card class="mb-4">
      <validation-observer
        ref="form"
      >
        <form
          autocomplete="off"
          @submit.prevent="onSubmit"
        >
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            :label="$t('routes.CREATE_ANNOUNCE')"
            label-for="announce"
          >
            <vue-editor v-model="form.announce" />
          </b-form-group>
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            label="ประกาศหลังจากล็อกอิน"
            label-for="announceAfterLogin"
          >
            <vue-editor v-model="form.announceAfterLogin" />
          </b-form-group>
          <div class="text-right">
            <b-overlay
              :show="isLoading"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="!form.announce && !form.announceAfterLogin"
              >
                {{ $t('buttons.save') }}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  page: {
    title: 'Announcement',
  },
  components: { VueEditor },
  data() {
    return {
      form: {
        announce: '',
        announceAfterLogin: '',
      },
    }
  },
  computed: {
     ...mapState({
      isLoading: (state) => state.setting.isSettingAnnounce,
    }),
    ...mapGetters([]),
    announcementId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
  },
  created() {
    if (this.announcementId) {
      // await this.fetchAssistant(this.assistantId)
    }
  },
  methods: {
    ...mapActions(['setAnnounce']),

    onSubmit() {
      this.setAnnounce(this.form)
    },
  },
}
</script>